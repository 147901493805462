import React from 'react';
import StyleBonusDropDown from '../components/StyleBonusDropDown.js'
import TotalQuarterPointsInput from '../components/TotalQuarterPointsInput.js';
import QuartersPlayedInput from '../components/QuartersPlayedInput.js';
import TotalStreaksInput from '../components/TotalStreaksInput.js';
import TotalStreakPointsInput from '../components/TotalStreakPointsInput.js';

class KOTCForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      quarter_style_bonus: 1.0,
      quarters_played: 0,
      quarter_total_points: 0,

      streak_style_bonus: 1.0,
      streaks_played: 0,
      streak_total_points: 0
    };
  }

  updateStreakStyleBonus(streak_style_bonus) {
    this.setState({
      streak_style_bonus: streak_style_bonus
    })

    this.props.updateState(streak_style_bonus, this.state.quarter_style_bonus, this.state.quarters_played)
  }

  updateQuarterStyleBonus(quarter_style_bonus) {
    this.setState({
      quarter_style_bonus: quarter_style_bonus
    })

    this.props.updateState(this.state.streak_style_bonus, quarter_style_bonus, this.state.quarters_played)
  }

  updateQuartersPlayedState(quarters_played) {
    this.setState({
      quarters_played: quarters_played
    })

    this.props.updateState(this.state.streak_style_bonus, this.state.quarter_style_bonus, quarters_played)
  }

  render() {
    return (
        <React.Fragment>
            <div className="form-row mx-auto">
                <span className="badge badge-primary">Streak</span>
            </div>
            <div className="form-row">
                <div className="col-xs-1 w-50">
                    <StyleBonusDropDown
                        type="Streak"
                        style_bonus={this.state.style_bonus}
                        quarters_played={this.state.quarters_played}
                        updateState={this.updateStreakStyleBonus.bind(this)} />
                </div>
            </div>
            <div className="row mt-3"></div>
            <div className="row mt-3"></div>

            <div className="form-row mx-auto">
                <span className="badge badge-primary">Quarter</span>
            </div>
            <div className="form-row">
                <div className="col-xs-1 w-50">
                    <StyleBonusDropDown
                        type="Quarter Lineup"
                        style_bonus={this.state.style_bonus}
                        quarters_played={this.state.quarters_played}
                        updateState={this.updateQuarterStyleBonus.bind(this)} />
                </div>
                <div className="col-xs-1 w-50">
                    <QuartersPlayedInput
                        style_bonus={this.state.style_bonus}
                        quarters_played={this.state.quarters_played}
                        updateState={this.updateQuartersPlayedState.bind(this)} />
                </div>
            </div>
            <div className="row mt-3"></div>

            <div className="form-row">
                <div className="col-xs-1 w-50">
                    <TotalQuarterPointsInput quarter_total_points={this.props.quarter_total_points} />
               </div>
            </div>

            <div className="form-row">
                <div className="col-xs-1 w-50">
                    <TotalStreakPointsInput
                        streak_total_points={this.props.streak_total_points} />
               </div>
                <div className="col-xs-1 w-50">
                    <TotalStreaksInput
                        streaks_played={this.props.streaks_played} />
                </div>
            </div>

        </React.Fragment>
    );
  }
}

export default KOTCForm;
