import React from 'react';

class TotalPointsInput extends React.Component {
  render() {
    return (
        <React.Fragment>
            <div className="row mt-3"></div>
            <table className="table table-sm w-100 p-3">
              <thead>
                <tr>
                  <th scope="col">Total Free Points</th>
                  <th scope="col">{Math.max(this.props.total_points, 0)}</th>
                </tr>
              </thead>
            </table>
        </React.Fragment>
    );
  }
}

export default TotalPointsInput;
