import React from 'react';

class TotalStreaksInput extends React.Component {
  render() {
    return (
        <React.Fragment>
            <label>Total Streaks</label>
            <input readOnly className="form-control" placeholder={this.props.streaks_played} />
        </React.Fragment>
    );
  }
}

export default TotalStreaksInput;
