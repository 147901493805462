import React from 'react';
import Calculator from './components/Calculator.js';
import './App.css';

function App() {
  return (
    <div className="App">
        <h1>1K Club KOTC Calculator</h1>
        <Calculator />
    </div>
  );
}

export default App;
