import React from 'react';

class StyleBonusDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.props.updateState(event.target.value);
  }

  render() {
    return (
        <React.Fragment>
          <label>{this.props.type} Style Bonus</label>
          <select className="form-control" onChange={this.handleChange}>
            <option value="1.0">0%</option>
            <option value="1.20">20%</option>
            <option value="1.40">40%</option>
            <option value="1.60">60%</option>
            <option value="1.80">80%</option>
            <option value="2.00">100%</option>
          </select>
        </React.Fragment>
    );
  }
}

export default StyleBonusDropDown;
