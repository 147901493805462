import React from 'react';
import DesiredEventPointsInput from '../components/DesiredEventPointsInput.js'
import TotalPointsInput from '../components/TotalPointsInput.js'
import CoinsNeeded from '../components/CoinsNeeded.js'
import KOTCForm from '../components/KOTCForm.js'

class Calculator extends React.Component {
  constructor(props) {
    super(props);

    this.round_one_points = 40
    this.round_two_points = 80
    this.round_three_points = 100
    this.one_streak_quarter_points = (2 * this.round_one_points + 2 * this.round_two_points + 2 * this.round_three_points)
    this.streak_points = 1500
    this.quarters_in_streak = 6

    this.state = {
      desired_points: 80000,

      quarter_style_bonus: 1.0,
      quarters_played: 0,
      quarter_total_points: 0,

      streak_style_bonus: 1.0,
      streaks_played: 0,
      streak_total_points: 0
    };

    this.updateDesiredPoints = this.updateDesiredPoints.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  updateDesiredPoints(desired_points) {
    this.setState({
      desired_points: desired_points
    })
  }

  updateState(streak_style_bonus, quarter_style_bonus, quarters_played) {
    const streaks_played = Math.floor(quarters_played / this.quarters_in_streak);
    const streak_total_points = streaks_played * streak_style_bonus * this.streak_points;

    this.setState({
      streak_style_bonus: streak_style_bonus,
      quarter_style_bonus: quarter_style_bonus,
      quarters_played: quarters_played,
      streaks_played: streaks_played,
      streak_total_points: streak_total_points,
      quarter_total_points: this.calculateQuarterTotalPoints(quarter_style_bonus, quarters_played, streaks_played)
    })
  }

  calculateQuarterTotalPoints(quarter_style_bonus, quarters_played, streaks_played) {
    const non_streak_quarters = quarters_played - (streaks_played * this.quarters_in_streak);
    const streak_quarter_points = this.one_streak_quarter_points * streaks_played;

    let non_streak_quarter_points = 0;

    if (non_streak_quarters > 0) {
        non_streak_quarter_points += Math.min(non_streak_quarters, 2) * this.round_one_points;
    }

    if(non_streak_quarters > 2) {
        non_streak_quarter_points += Math.min(non_streak_quarters - 2, 2) * this.round_two_points;
    }

    if(non_streak_quarters > 4) {
        non_streak_quarter_points += Math.min(non_streak_quarters - 4, 2) * this.round_three_points;
    }

    return (streak_quarter_points + non_streak_quarter_points) * quarter_style_bonus;
  }

  render() {
    return (
      <React.Fragment>
        <DesiredEventPointsInput desired_points={this.state.desired_points} updateDesiredPoints={this.updateDesiredPoints} />
        <div className="row mt-3"></div>
        <div className="row mt-3"></div>
        <table className="table table-sm w-100 p-3">
          <thead>
            <tr>
              <th scope="col">Enter Style Bonus & Any Free Play Quarters</th>
            </tr>
          </thead>
        </table>
        <KOTCForm type="EASY"
            streaks_played={this.state.streaks_played}
            streak_total_points={this.state.streak_total_points}
            quarter_total_points={this.state.quarter_total_points}
            quarter_points={[this.round_one_points, this.round_two_points, this.round_three_points]}
            updateState={this.updateState} />
        <TotalPointsInput total_points={this.state.quarter_total_points + this.state.streak_total_points} />

        <CoinsNeeded
            total_points={this.state.quarter_total_points + this.state.streak_total_points}
            desired_points={this.state.desired_points}
            one_streak_quarter_points={this.one_streak_quarter_points * this.state.quarter_style_bonus}
            one_streak_points={this.streak_points * this.state.streak_style_bonus} />
      </React.Fragment>
    );
  }
}

export default Calculator;
