import React from 'react';

class CoinsNeeded extends React.Component {

  constructor(props) {
    super(props);

    this.large_recharge_quarter_quantity = 6;
    this.large_recharge_cost = 180;
  }

  remainingEventPoints() {
    return Math.max(this.props.desired_points - this.props.total_points, 0)
  }

  calculateCoins(one_streak_quarter_points, one_streak_points) {
    const single_pack_points = one_streak_quarter_points + one_streak_points;
    const number_of_energy_packs = Math.ceil(this.remainingEventPoints() / single_pack_points);

    return {
        number_of_energy_packs: number_of_energy_packs,
        coin_cost: number_of_energy_packs * this.large_recharge_cost,
        total_points: number_of_energy_packs * single_pack_points,
        total_quarters: number_of_energy_packs * this.large_recharge_quarter_quantity
    }
  }

  render() {
    return (
        <React.Fragment>
            <div className="row mt-3"></div>
            <table className="table table-sm w-100 p-3">
              <thead>
                <tr>
                  <th scope="col">Remaining Event Points Needed</th>
                  <th scope="col">{Math.max(this.remainingEventPoints(), 0)}</th>
                </tr>
              </thead>
            </table>
            <div className="row mt-3"></div>
            <table className="table table-sm w-100 p-3">
              <thead>
                <tr>
                  <th scope="col">Large Event Recharge Packs</th>
                </tr>
              </thead>
            </table>
            <table className="table table-sm w-100 p-3">
              <thead>
                <tr>
                  <th scope="col">Quantity</th>
                  <th scope="col">Coins</th>
                  <th scope="col">Points</th>
                  <th scope="col">Qtrs</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">{this.calculateCoins(this.props.one_streak_quarter_points, this.props.one_streak_points).number_of_energy_packs}</th>
                  <td>{this.calculateCoins(this.props.one_streak_quarter_points, this.props.one_streak_points).coin_cost}</td>
                  <td>{this.calculateCoins(this.props.one_streak_quarter_points, this.props.one_streak_points).total_points}</td>
                  <td>{this.calculateCoins(this.props.one_streak_quarter_points, this.props.one_streak_points).total_quarters}</td>
                </tr>
              </tbody>
            </table>
        </React.Fragment>
    );
  }
}

export default CoinsNeeded;
