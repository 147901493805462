import React from 'react';

class DesiredEventPointsInput extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.props.updateDesiredPoints(event.target.value);
  }

  render() {
    return (
        <React.Fragment>
            <div className="col-md-3 form-inline form-horizontal">
                <label className="control-label col-md-5">Desired Event Points</label>
                <div className="input-group col-sm-7">
                    <input type="number" min="0" className="form-control"
                        placeholder={this.props.desired_points}
                        onChange={this.handleChange} />
                </div>
            </div>
        </React.Fragment>
    );
  }
}

export default DesiredEventPointsInput;
