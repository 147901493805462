import React from 'react';

class QuartersPlayedInput extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.props.updateState(event.target.value);
  }

  render() {
    return (
        <React.Fragment>
            <label>Qtr Won (6 Qtrs = 1 Streak)</label>
            <input type="number" className="form-control" min="0" placeholder="0" onChange={this.handleChange}  />
        </React.Fragment>
    );
  }
}

export default QuartersPlayedInput;
