import React from 'react';

class TotalStreakPointsInput extends React.Component {

  render() {
    return (
        <React.Fragment>
            <label>Total Streak Pts</label>
            <input
                readOnly
                className="form-control" placeholder={this.props.streak_total_points} />
        </React.Fragment>
    );
  }

}

export default TotalStreakPointsInput;
